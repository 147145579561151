<template>
  <div class="app-page">
    <Navbar />
    <div class="main-wrapper">
      <LeftMenu />
      <div class="registry-wrapper container-fluid">
        <div class="row">
          <div class="col-12 px-0">
            <div
              v-if="showloader == 0"
              class="lds-dual-ring-registry"
              id="loading"
            ></div>
          </div>
          <div class="col-lg-6 pl-0">
            <CompanyDetailView v-if="showloader == 1" />
          </div>
          <div class="col-lg-6 mt-3 px-0">
            <NDAFormTable />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from "../../components/Enterprise/leftmenu/LeftMenu.vue";
import Navbar from "../../components/Enterprise/header/Navbar.vue";
import CompanyDetailView from "../../components/Enterprise/agreementdetail/CompanyDetailView.vue";
import { mapGetters } from "vuex";
import axios from "axios";
import NDAFormTable from "../../components/Enterprise/ndaform/NDAFormTable.vue";
export default {
  name: "EnterpriseCompanyAgreements",

  data() {
    return {
      details: {},
      preDATA:{},
      showloader: 0,
      fullPage: false,
      loading: true,
    };
  },
  components: {
    Navbar,
    LeftMenu,
    CompanyDetailView,
    NDAFormTable
},
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods:{
    getData(){
      var formData = new FormData();
      formData.append("registry_id", this.preDATA.id);
      axios
        .post(process.env.VUE_APP_API_URL + "company/view", formData)
        .then((response) => {
          if (response.data.statusCode === 200) {
              this.details = response.data.data;
              
              // console.log(this.details)
              setTimeout(() => {
                this.$root.$emit("agreeViewTable", this.details);
                this.$root.$emit("ndaAgreeTable", this.details);
            }, 500);
              
              this.showloader = 1;
          }
        })
        .catch((error) => {
          if (error.response.status === 500) {
            alert(error.response.data.message);
          }
        });

    }
  },

  
  mounted() {
      
    this.$root.$on("dataForCompanyView", (data) => {
      this.preDATA = data
      this.getData();
    });
    if(this.user.agreement != null ){
      this.preDATA = this.user.agreement
      this.getData();
    }
  },
};
</script>
<style scoped>
.lds-dual-ring-registry {
  position: absolute;
  z-index: 999;
  padding-top: 50% !important;
}
</style>
